<template>
  <div class="my-governace">
    <a-back-top :target="targetFn"
      ><div class="cornerButtons"><div class="cornerButton">↑</div></div>
    </a-back-top>
    <a-layout id="components-layout">
      <a-layout-header>
        <Menu></Menu>
      </a-layout-header>
      <a-layout-content>
        <keep-alive>
          <router-view class="body"></router-view>
        </keep-alive>
      </a-layout-content>
      <a-layout-footer style="padding: 0">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import Footer from "./Footer.vue";
export default {
  data() {
    return {
      transitionName: "",
    };
  },
  methods: {
    targetFn() {
      return document.querySelector(".my-governace");
    },
  },
  components: {
    Menu,
    Footer,
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      this.targetFn().scrollTo(0, 0);
    });
  },
};
</script>

<style lang="scss" scoped>
.cornerButtons {
  position: fixed;
  bottom: 10%;
  right: 10%;
  z-index: 10;
  .cornerButton {
    cursor: pointer;
    width: 120px;
    height: 116px;
    background: #fcf5ea;
    border: 1px solid #fcf5ea;
    border-radius: 100px 0px 100px 100px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.my-governace {
  min-width: 650px;
  height: 100%;
  overflow: auto;
  #components-layout {
    background: #fff;
  }
}
</style>
