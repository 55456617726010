<template>
  <div class="home_nav">
    <!-- :selectedKeys="[$route.path]" -->
    <a-menu
      mode="horizontal"
      :defaultSelectedKeys="[home]"
      @animationend="reset"
    >
      <a-menu-item>
        <img class="home_img" src="../assets/banner/image13@2x.png" alt="" />
      </a-menu-item>
      <a-menu-item :key="'/Home'" @click="handleClick('Home')">
        <p :class="{ translate: showAnimate }">首页</p>
      </a-menu-item>
      <a-menu-item :key="'/Technology'" @click="handleClick('Technology')">
        <p :class="{ translate: showAnimate }">技术支持</p></a-menu-item
      >
      <a-menu-item key="/Product" @click="handleClick('Product')">
        <p :class="{ translate: showAnimate }">产品中心</p></a-menu-item
      >
      <a-menu-item key="/About" @click="handleClick('About')">
        <p :class="{ translate: showAnimate }">关于我们</p>
      </a-menu-item>
      <a-menu-item key="/discoverMap" @click="handleClick('discoverMap')">
        <p :class="{ translate: showAnimate }">发现地图</p></a-menu-item
      >
      <a-menu-item key="/lightWorld" @click="handleClick('lightWorld')">
        <p :class="{ translate: showAnimate }">光影世界</p></a-menu-item
      >
      <a-menu-item key="/mapWallpaper" @click="handleClick('mapWallpaper')"
        ><p :class="{ translate: showAnimate }">地图壁纸</p></a-menu-item
      >
      <a-menu-item key="/Login" @click="handleClick('')"
        ><p :class="{ translate: showAnimate }">登录</p></a-menu-item
      >
    </a-menu>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      showAnimate: false,
    };
  },
  mounted() {},
  methods: {
    handleClick(val) {
      this.showAnimate = true;
      if (this.$route.name != val && val) {
        this.$router.push(`/${val}`);
      }
    },
    reset() {
      this.showAnimate = false;
    },
  },
  computed: {
    home() {
      const str = "/discoverMap";
      if (this.$route.path.includes(str)) {
        return str;
      }
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.home_nav {
  min-width: 1024px;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;

  .ant-menu {
    margin-left: 10%;
    background-color: rgba(255, 255, 255, 0);
    border: none !important;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    .ant-menu-item {
      border: none !important;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        font-family: Roboto, Roboto-Bold;
      }
    }
    .ant-menu-item-selected {
      .translate {
        animation: translate 0.5s ease 0s;
      }

      @keyframes translate {
        100% {
          transform: translateY(-10px);
        }
      }
    }
  }
}

.home_img {
  width: 77px;
}
</style>
