<template>
  <div>
    <footer class="footer">
      <div class="column">
        <div class="column_content">
          <div class="column_title">技术支持</div>
          <ul class="column_text">
            <li><span>问题解答</span></li>
            <li><span>下载中心</span></li>
          </ul>
        </div>
        <div class="column_content">
          <div class="column_title">产品中心</div>
          <ul class="column_text">
            <li><span>蜗牛地图</span></li>
          </ul>
        </div>
        <div class="column_content">
          <div class="column_title">解决方案</div>
          <ul class="column_text">
            <li><span>林业</span></li>
            <li><span>林业</span></li>
            <li><span>国土</span></li>
            <li><span>水利</span></li>
            <li><span>生态环境</span></li>
            <li><span>智慧城市</span></li>
          </ul>
        </div>
        <div class="column_content">
          <div class="column_title column_title_center">联系我们</div>
          <ul class="column_text column_text_left">
            <li>
              <span><span>邮箱：</span>suntoon@3map.com.cn</span>
            </li>
            <li><span>电话：</span>4008-655-995</li>
            <li><span>地址：西安市高新区科创路168号西电科技园</span></li>
            <li><span>联系人：叶</span></li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <span>备案号：陕ICP备2022004588号</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  height: 100%;
  background: #000 url("~@/assets/world.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 50px;
  color: #fff;
  .column {
    display: flex;
    justify-content: space-around;
    padding: 10px 50px;
    box-sizing: border-box;
    .column_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .column_title {
        font-size: 16px;
        font-family: Montserrat, Montserrat-SemiBold;
        font-weight: bold;
      }
      .column_title_center {
        align-self: flex-start;
      }
      .column_text {
        font-size: 14px;
        padding: 0;
        list-style: none;
        line-height: 18px;
        text-align: center;
        li {
          margin-top: 10px;
          span {
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .column_text_left {
        text-align: left;
      }
    }
  }
  .copyright {
    margin-top: 10%;
    align-self: center;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 600px) {
  .footer {
    height: 100%;
    align-items: center;
    .column {
      flex-direction: column;
      .column_content {
        .column_title_center {
          align-self: center;
        }
      }
    }
  }
}
</style>
